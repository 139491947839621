import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtDecoder {
  getBody(token: string): object {
    try {
      const parts = token.split('.');
      const bodyPart = parts[1];

      const decoded = atob(bodyPart);
      return JSON.parse(decoded);
    } catch (e) {
      console.error('Could not decode JWT');
      return {};
    }
  }
}
