import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, UserAdapter } from '@core/models';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '@env';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  //private baseUrl:string = environment.apiEndpoint+'login_check';  // URL to web api

  constructor(
     private router: Router,
     private http: HttpClient,
     private userAdapter: UserAdapter
   ) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
     return this.currentUserSubject.value;
   }

   get currentUserChanged() {
     return this.currentUserSubject.asObservable();
   }

   get hasValidAccessToken(){
       return true;
   }

   login(email:string, password:string ){
    return this.http.post<User>(`${environment.apiUrl}/authentication_token`, { email, password })
        .pipe(map(response => {
            const user = this.userAdapter.adapt(response.token);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        }));
  }

  sendPassword(email:string){
    return this.http.post<string>(`${environment.apiUrl}/authentication_token`, { email })
    .pipe(map(response => {
        return response;
    }));
  }

  getBusinessId(): string {
    return this.currentUserValue.businessId;
  }

  getUserId(): string {
    return this.currentUserValue.id;
  }

   updateUser(u: User){
     localStorage.setItem('currentUser', JSON.stringify(u));
     this.currentUserSubject.next(u);
   }
   logout(){
     localStorage.removeItem('currentUser');
     this.currentUserSubject.next(null);
     this.router.navigate(["login"]);
   }
}
