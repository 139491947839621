import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/helpers/auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: '', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule), canActivate: [AuthGuard] },
  { path: 'settings/afhalingen', loadChildren: () => import('./takeout-settings/takeout-settings.module')
      .then(m => m.TakeoutSettingsModule), canActivate: [AuthGuard] },
  { path: 'settings/account', loadChildren: () => import('./account-settings/account-settings.module')
      .then(m => m.AccountSettingsModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
