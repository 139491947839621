import { Injectable } from '@angular/core';
import { Adapter } from '@core/models/adapters';
import * as moment from 'moment';

export interface TakeoutSettings {
  activeDays: {
    mo: ActiveTakeoutDay
    tu: ActiveTakeoutDay
    we: ActiveTakeoutDay
    th: ActiveTakeoutDay
    fr: ActiveTakeoutDay
    sa: ActiveTakeoutDay
    su: ActiveTakeoutDay
  };
  activeFromDate: string;
  activeUntilDate: string;
}
export interface ActiveTakeoutDay {
  day: number;
  name: string;
  active: boolean;
  lunchShift: TakeoutShift;
  dinnerShift: TakeoutShift;
}
export interface TakeoutShift {
  from: string;
  until: string;
}
export interface TakeoutDate {
  id: string|null;
  name: string;
  date: moment.Moment;
  deadline: moment.Moment;
  open: boolean;
  activeHours: ActiveHours;
}
export interface ActiveHours {
  lunchShift: TakeoutShift;
  dinnerShift: TakeoutShift;
}
export interface PickupDayDTO {
  id: string|null;
  date: string;
  isOutOfStock: boolean;
  orderDeadline: string;
  business: string;
  pickupHours: PickupHoursDTO[];
}
export interface PickupHoursDTO {
  id: string|null;
  timeFrom: string;
  timeUntil: string;
}

@Injectable({providedIn: 'root'})
export class TakeoutAdapter implements Adapter<TakeoutDate> {
  adapt(dto: PickupDayDTO): TakeoutDate {
    const date: moment.Moment = moment(dto.date);
    const deadline: moment.Moment = moment(dto.orderDeadline);
    return {
      id: dto.id,
      name: this.getDayName(date.day()),
      date,
      deadline,
      open: !dto.isOutOfStock,
      activeHours: this.getActiveHours(dto.pickupHours)
    };
  }

  private getActiveHours(hours: PickupHoursDTO[]): any {
    let lunch = false;
    let dinner = false;
    switch (hours.length) {
      case 0:
        break;
      case 1:
        lunch = true;
        break;
      case 2:
      default:
        lunch = true;
        dinner = true;
        break;
    }

    return {
      lunchShift: this.buildShift(lunch ? hours[0] : null),
      dinnerShift: this.buildShift(dinner ? hours[1] : null),
    };
  }

  private buildShift(hours: PickupHoursDTO|null): TakeoutShift {
    if (hours === null) {
      return {from: '', until: ''};
    }

    const from = moment(hours.timeFrom);
    const until = moment(hours.timeUntil);
    return {
      from: from.format('HH:mm'),
      until: until.format('HH:mm')
    };
  }

  private getDayName(day: number): string {
    switch (day) {
      case 1: return 'Maandag';
      case 2: return 'Dinsdag';
      case 3: return 'Woensdag';
      case 4: return 'Donderdag';
      case 5: return 'Vrijdag';
      case 6: return 'Zaterdag';
      case 7:
      case 0:
        return 'Zondag';
      default: return '';
    }
  }
}
