import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@services/api.service';
import { ProductAdapter } from '@core/models/product';
import { TakeoutAdapter } from '@core/models/takeout-settings';
import { AuthenticationService } from '@services/auth.service';

@Injectable({ providedIn: 'root' })
export class TakeoutService extends ApiService {
  constructor(http: HttpClient, takeoutAdapter: TakeoutAdapter, authService: AuthenticationService) {
    super(http, takeoutAdapter, authService);
  }

  protected isBusinessSpecific(): boolean {
    return true;
  }

  protected getEndpoint(): string {
    return '/pickup_days';
  }
}
