import { Injectable } from '@angular/core';
import { Adapter } from '@core/models/adapters';
import { Product, ProductAdapter, ProductDTO } from '@core/models/product';

export type Template = 'listWithImages' | 'listWithoutImages';
export type Semantics = 'foods' | 'drinks';
export interface CategoryDTO {
  id: string;
  name: string;
  template: Template;
  semantics: Semantics;
  isVisible: boolean;
  products: ProductDTO[];
  business: string;
  priority: number;
}
export class Category {
  constructor(
    public id: string,
    public name: string,
    public template: Template,
    public semantics: Semantics,
    public isVisible: boolean,
    public products: Product[],
    public business: string,
    public priority: number
  ) {}
}
@Injectable({providedIn: 'root'})
export class CategoryAdapter implements Adapter<Category> {
  constructor(private productAdapter: ProductAdapter) {}

  adapt(dto: CategoryDTO): Category {
    return new Category(
      dto.id,
      dto.name,
      dto.template,
      dto.semantics,
      dto.isVisible,
      dto.products.map(this.productAdapter.adapt),
      dto.business,
      dto.priority
    );
  }
}
