import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@services/api.service';
import { CategoryAdapter } from '@core/models/category';
import { AuthenticationService } from '@services/auth.service';

@Injectable({ providedIn: 'root' })
export class CategoryService extends ApiService {
  getEndpoint(): string {
    return '/categories';
  }

  protected isBusinessSpecific(): boolean {
    return true;
  }

  constructor(http: HttpClient, categoryAdapter: CategoryAdapter, authService: AuthenticationService) {
    super(http, categoryAdapter, authService);
  }
}
