import { Injectable } from '@angular/core';
import { Adapter } from '@core/models/adapters';

export interface ProductDTO {
  id: string;
  name: string;
  details: string;
  price: string;
  category: string;
  minimumOrderQuantity: number;
  isVisible: boolean;
  isOutOfStock: boolean;
  images: string[];
  imageUrl: string;
  inSpotlight: boolean;
  priority: number;
}
export class Product {
  constructor(
    public id: string,
    public name: string,
    public details: string,
    public price: string,
    public category: string,
    public minimumOrderQuantity: number,
    public isVisible: boolean,
    public isOutOfStock: boolean,
    public imageId: string|null,
    public imageUrl: string,
    public inSpotlight: boolean,
    public priority: number
  ) {}
}
@Injectable({providedIn: 'root'})
export class ProductAdapter implements Adapter<Product> {
  adapt(dto: ProductDTO): Product {
    let imageId = null;
    if (dto.images.length > 0) {
      imageId = dto.images.shift();
    }
    return new Product(
      dto.id,
      dto.name,
      dto.details,
      dto.price,
      dto.category.replace('/categories/', ''),
      dto.minimumOrderQuantity,
      dto.isVisible,
      dto.isOutOfStock,
      imageId,
      dto.imageUrl,
      dto.inSpotlight,
      dto.priority
    );
  }
}
