import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { Globals } from '@core/globals';
import { AccountService } from '@services/account.service';
import { AuthenticationService } from '@services/auth.service';
import { AccountSettings } from '@core/models/account-settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{
  title = 'munch-app';
  isLoading = false;
  constructor(
    private elementRef: ElementRef
  ) {
    Globals.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    // force remove all workers
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }

  ngAfterViewInit(): void {
    this.removePeskyServiceWorkers();

    // add the helpdesk support widget
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://widget.freshworks.com/widgets/3000000192.js';
    this.elementRef.nativeElement.appendChild(s);

    // @ts-ignore
    window.fwSettings = {
      widget_id: 3000000192
    };

    s.onload = () => {
      // @ts-ignore
      if ('function' !== typeof window.FreshworksWidget) {
        // @ts-ignore
        const n = () => {
          // @ts-ignore
          n.q.push(arguments);
        };
        // @ts-ignore
        n.q = [], window.FreshworksWidget = n;
      }
    };
  }

  removePeskyServiceWorkers(): void {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}
