import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { catchError, map, retry } from 'rxjs/operators';
import { Adapter } from '@core/models/adapters';
import { AuthenticationService } from '@services/auth.service';

export abstract class ApiService {
  protected constructor(
    private http: HttpClient,
    private adapter: Adapter<any>,
    private authService: AuthenticationService,
  ) {
  }

  protected abstract getEndpoint(): string;
  protected abstract isBusinessSpecific(): boolean;

  private getData(): Observable<any[]> {
    return this.http.get(this.getBaseUrl() + this.getEndpoint()).pipe(
      retry(0),
      map((collection: any[]): any[] => {
        return collection.map(dto => this.adapter.adapt(dto));
      }),
      catchError((err, caught) => {
        console.log(err, caught);
        return [];
      })
    );
  }

  public all(): Observable<any[]> {
    return this.getData();
  }

  public filter(filterFn: (obj: any) => boolean): Observable<any> {
    return this.getData().pipe(
      map(data => data.filter(filterFn))
    );
  }

  public get(id: string): Observable<any> {
      return this.http.get(environment.apiUrl + this.getEndpoint() + '/' + id).pipe(
        map(data => this.adapter.adapt(data))
      );
  }

  private getBaseUrl(): string {
    if (this.isBusinessSpecific()) {
      return environment.apiUrl + '/businesses/' + this.authService.getBusinessId();
    }

    return environment.apiUrl;
  }

  public post(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + this.getEndpoint(), data).pipe(
      map((createdObject: any): any => {
        return this.adapter.adapt(createdObject);
      }),
      catchError((err, caught) => {
        console.log(err, caught);
        return null;
      }),
    );
  }

  public put(data: any): Observable<any> {
    return this.http.put(environment.apiUrl + this.getEndpoint() + '/' + data.id, data).pipe(
      map((object: any): any => {
        return this.adapter.adapt(object);
      }),
      catchError((err, caught) => {
        console.log(err, caught);
        return null;
      }),
    );
  }

  public patch(data: any, updateDataset: boolean = false): Observable<any> {
    const options = {headers: {'Content-Type': 'application/merge-patch+json'}};
    return this.http.patch(environment.apiUrl + this.getEndpoint() + '/' + data.id, data, options).pipe(
      map((object: any): any => {
        return this.adapter.adapt(object);
      }),
      catchError((err, caught) => {
        console.log(err, caught);
        return null;
      }),
    );
  }

  public delete(objectToDelete: any): Observable<any> {
    return this.http.delete(environment.apiUrl + this.getEndpoint() + '/' + objectToDelete.id).pipe(
      map((): void => {
        return objectToDelete;
      }),
      catchError((err, caught) => {
        console.log(err, caught);
        return null;
      }),
    );
  }
}
