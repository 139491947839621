import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@services/api.service';
import { ProductAdapter } from '@core/models/product';
import { AuthenticationService } from '@services/auth.service';

@Injectable({ providedIn: 'root' })
export class ProductService extends ApiService {
  getEndpoint(): string {
    return '/products';
  }

  protected isBusinessSpecific(): boolean {
    return false;
  }

  constructor(http: HttpClient, productAdapter: ProductAdapter, authService: AuthenticationService) {
    super(http, productAdapter, authService);
  }
}
