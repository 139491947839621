import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/auth.service';

import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TakeoutService } from '@core/services/takeout.service';
import * as moment from 'moment';
import { skip } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {


  constructor(private authService: AuthenticationService, private router: Router,private dialog: MatDialog, private takeoutService:TakeoutService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      if (this.authService.currentUserValue) {
                
        let daysalert = localStorage.getItem('daysalert');
        if(!daysalert){
          
          this.takeoutService.all().pipe(skip(1)).subscribe(activeDates => {
            let dates = activeDates.sort();
            let today = moment();
            let index = dates.filter(x => (x.date).isAfter(today) == true && x.open);
            
            if(index.length < 5){
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '400px',
                disableClose: true
              });
              dialogRef.componentInstance.confirmMessage = 'Gelieve uw afhaalinstellingen aan te passen.';
              dialogRef.componentInstance.confirmTitle = 'Er zijn momenteel '+ (index.length > 0 ? 'nog maar ' + index.length :'geen') + ' afhaaldagen beschikbaar.';
              dialogRef.componentInstance.confirmBtn = 'Ga naar afhaalinstellingen';
              dialogRef.componentInstance.cancelBtn = 'Annuleren';
              dialogRef.afterClosed().subscribe(result => {
      
                localStorage.setItem("daysalert","1");
                if(result){
                  this.router.navigate(['/settings/afhalingen']);
                }
              });
            }
          });
        }
        return true;
      }else{
        
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '400px',
          disableClose: true
        });
    
        dialogRef.componentInstance.confirmMessage = 'Gelieve opnieuw in te loggen.';
        dialogRef.componentInstance.confirmTitle = 'Uw sessie is verlopen';
        dialogRef.componentInstance.confirmBtn = 'OK';
        dialogRef.componentInstance.cancelBtn = '';
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        });

        
        return false;
      }
    }
}
