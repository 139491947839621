import { Injectable } from '@angular/core';
import { Adapter } from './adapters';
import { JwtDecoder } from '@core/helpers/jwt.decoder';


interface UserDTO {
  id: string;
  iat: number;
  exp: number;
  roles: Array<string>;
  username: string;
  business: string;
  name: string;
}
export class User {
    constructor(
    public id: string,
    public name: string,
    public email: string,
    public token: string,
    public issuedAt: number,
    public expiresAt: number,
    public businessId: string,
    public image: string = '',
    ){}
}

@Injectable({
  providedIn: 'root'
})

export class UserAdapter implements Adapter<User> {
  constructor(private jwtDecoder: JwtDecoder) {}

  adapt(token: string): User {
    const tokenBody: UserDTO = this.jwtDecoder.getBody(token) as UserDTO;
    return new User(
      tokenBody.id,
      tokenBody.name,
      tokenBody.username,
      token,
      tokenBody.iat,
      tokenBody.exp,
      tokenBody.business
    );
  }
}
