import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { OrderDTO } from '@core/models/order';

// array in local storage for registered users
const usersKey = 'munchtestKey';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        return handleRoute();

        function handleRoute() {
            switch (true) {
                // case url.endsWith('/users/authenticate') && method === 'POST':
                //     return authenticate();
                // case url.endsWith('/users/register') && method === 'POST':
                //     return register();
                // case url.endsWith('/users') && method === 'GET':
                //     return getUsers();
                // case url.match(/\/users\/\d+$/) && method === 'GET':
                //     return getUserById();
                // case url.match(/\/users\/\d+$/) && method === 'PUT':
                //     return updateUser();
                // case url.match(/\/users\/\d+$/) && method === 'DELETE':
                //     return deleteUser();
                // case url.endsWith('/orders') && method === 'GET':
                //     return getOrders();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
